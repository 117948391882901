function loadGlance() {
    const element = document.createElement('script');
  
    element.id = "glance-cobrowse"
    element.setAttribute("data-groupid", "19881")
    element.setAttribute("data-inputevents", '{"ctrl-66":"startSession"}')
    element.type = 'text/javascript';

    const currentTier = window.LC_TIER ?? window.config?.LC_TIER;

    if (["prod", "sandbox", "training"].includes(currentTier)) {
        element.setAttribute("data-site", "production")
        element.src = "https://www.glancecdn.net/cobrowse/CobrowseJS.ashx?group=19881&site=production";
    } else {
        element.setAttribute("data-site", "staging")
        element.src = "https://www.glancecdn.net/cobrowse/CobrowseJS.ashx?group=19881&site=staging";
    }

    document.head.appendChild(element);
}

loadGlance();